import type { RestrictionLevel, RestrictionLevelThresholds } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { produce } from "immer";
import { type ReactNode, useState } from "react";
import LR from "../../components/LeftRight.module.css";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect } from "../../components/forms/WSelect.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import {
    updateDefaultActivePostPaymentLevelMutation,
    updateFirstAccountCreditMutation,
    updateLowBalanceNoticeThresholdMutation,
    updateMinTopupAmountMutation,
    updateRestrictionLevelClearTopupThresholdMutation,
    updateRestrictionLevelThresholdsMutation,
} from "./settingsQuery.ts";

interface ConfigPaymentModalProps {
    value: number | string;
    type: string;
    title: string;
    level?: RestrictionLevel;
    thresholds?: RestrictionLevelThresholds;
}

interface PaymentMethodButtonProps extends ConfigPaymentModalProps {
    button: ReactNode;
}

export function ConfigPaymentButton(props: PaymentMethodButtonProps) {
    return (
        <WModal button={props.button}>
            <ConfigPaymentModalContent {...props} />
        </WModal>
    );
}

function ConfigPaymentModalContent(props: ConfigPaymentModalProps) {
    const { value, type, title, level, thresholds } = props;
    const firstMutation = useStandardMutation(updateFirstAccountCreditMutation);
    const topupMutation = useStandardMutation(updateMinTopupAmountMutation);
    const levelMutation = useStandardMutation(updateRestrictionLevelClearTopupThresholdMutation);
    const defaultMutation = useStandardMutation(updateDefaultActivePostPaymentLevelMutation);
    const restrictionMutation = useStandardMutation(updateRestrictionLevelThresholdsMutation);
    const balanceMutation = useStandardMutation(updateLowBalanceNoticeThresholdMutation);
    const [current, setCurrent] = useState<number | string>(value ?? "");

    const levels = ["CLEAR", "LIMITED"];

    async function onSubmit() {
        if (type === "first_account_credit") {
            await firstMutation.mutateAsync({
                body: { first_account_credit: Number(current) },
            });
        } else if (type === "min_topup_amount") {
            await topupMutation.mutateAsync({
                body: { min_topup_amount: Number(current) },
            });
        } else if (type === "restriction_level_clear_topup_threshold") {
            await levelMutation.mutateAsync({
                body: { restriction_level_clear_topup_threshold: Number(current) },
            });
        } else if (type === "default_active_post_payment_level") {
            await defaultMutation.mutateAsync({
                body: { default_active_post_payment_level: current as RestrictionLevel },
            });
        } else if (type === "restriction_level_thresholds") {
            const body = produce(thresholds ?? [], (body) => {
                const item = body.find((_p) => _p.restriction_level === level);
                if (item) {
                    item.days = Number(current);
                } else {
                    body.push({
                        days: Number(current),
                        restriction_level: notNull(level),
                    });
                }
            });

            await restrictionMutation.mutateAsync({
                body: body,
            });
        } else if (type === "low_balance_notice_threshold") {
            await balanceMutation.mutateAsync({
                body: current ? { threshold: Number(current) } : {},
            });
        }
    }

    return (
        <WModalContent
            title={`Update ${title.toLowerCase()}`}
            label="Update"
            modalAction={async () => await onSubmit()}
        >
            <WTextField
                isRequired={type !== "low_balance_notice_threshold"}
                autoFocus
                className="max-w-24rem"
                wide
                label={title}
                onChange={setCurrent}
            >
                {type === "default_active_post_payment_level" ? (
                    <WSelect
                        valueKey={current}
                        getKey={(item) => item}
                        items={levels}
                        itemClassName={LR.item}
                        valueClassName={LR.value}
                        aria-label="Select status"
                        getTextValue={(item) => item}
                        onChange={(item) => setCurrent(item)}
                    >
                        {(item) => {
                            return <LeftRight title={item} />;
                        }}
                    </WSelect>
                ) : (
                    <AInput value={current} />
                )}
            </WTextField>
        </WModalContent>
    );
}
