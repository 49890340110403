import { Suspense, useMemo } from "react";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { Separator } from "../../components/Separator.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { useConfig } from "../../config.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { ConfigPaymentButton } from "./ConfigPaymentModal.tsx";
import { configPaymentQueryAtom, configVmLimitsQueryAtom } from "./settingsQuery.ts";
import { VmLimitsButton } from "./VmLimitsModal.tsx";

function GeneralViewCustom() {
    const { allowDebt } = useConfig();
    const settings = useSuspenseQueryAtom(configPaymentQueryAtom);
    const limits = useSuspenseQueryAtom(configVmLimitsQueryAtom);

    let {
        first_account_credit,
        default_active_post_payment_level,
        min_topup_amount,
        restriction_level_clear_topup_threshold,
        restriction_level_thresholds,
        low_balance_notice_threshold,
    } = settings;

    // XXX: This is nullable in the API... should it be? should we handle it here?
    restriction_level_clear_topup_threshold ??= 0;

    const restrictionLevelThresholds = useMemo(() => {
        const frozen = restriction_level_thresholds?.length
            ? restriction_level_thresholds.find((_p) => _p.restriction_level === "FROZEN")
            : { days: 0 };

        const terminated = restriction_level_thresholds?.length
            ? restriction_level_thresholds.find((_p) => _p.restriction_level === "TERMINATED")
            : { days: 0 };

        return {
            frozen,
            terminated,
        };
    }, [restriction_level_thresholds]);

    return (
        <>
            <AdminTitle title="General" />

            {/* <SettingsHero /> */}

            <div className="p-2">
                <p className="color-muted">
                    These settings apply all over platform. Make sure you know what you are doing before making any
                    changes, changes apply once they are made and will affect end-users.
                </p>
            </div>

            <Separator />

            <WAdminTable>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td>First free credit amount</td>
                        <td>
                            The amount of free credit that customer gets from the platform with first billing account.
                        </td>
                        <td>
                            <ConfigPaymentButton
                                button={
                                    <EditableButton action={undefined}>
                                        <CurrencyPromo value={first_account_credit} />
                                    </EditableButton>
                                }
                                title="First free credit amount"
                                type="first_account_credit"
                                value={first_account_credit}
                            />
                        </td>
                    </tr>
                    {allowDebt && (
                        <tr>
                            <td>Restriction level for valid accounts</td>
                            <td>Default restriction level for billing accounts that have been verified.</td>
                            <td>
                                <ConfigPaymentButton
                                    button={
                                        <EditableButton action={undefined}>
                                            {default_active_post_payment_level}
                                        </EditableButton>
                                    }
                                    title="Default active post payment level"
                                    type="default_active_post_payment_level"
                                    value={default_active_post_payment_level}
                                />
                            </td>
                        </tr>
                    )}
                    {!allowDebt && (
                        <tr>
                            <td>Minimum topup amount</td>
                            <td>The minimum topup amount customer is allowed to buy.</td>
                            <td>
                                <ConfigPaymentButton
                                    button={
                                        <EditableButton action={undefined}>
                                            <CurrencyPromo value={min_topup_amount} />
                                        </EditableButton>
                                    }
                                    title="Minimum topup amount"
                                    type="min_topup_amount"
                                    value={min_topup_amount}
                                />
                            </td>
                        </tr>
                    )}
                    {!allowDebt && (
                        <tr>
                            <td>CLEAR level top up threshold</td>
                            <td>
                                Total amount of top ups a billing account has to make to have their restriction level
                                automatically set from LIMITED to CLEAR. Setting this smaller or equal to minimum top up
                                amount effectively disables the LIMITED level, moving accounts to CLEAR right after
                                their first top up.
                            </td>
                            <td>
                                <ConfigPaymentButton
                                    button={
                                        <EditableButton action={undefined}>
                                            <CurrencyPromo value={restriction_level_clear_topup_threshold} />
                                        </EditableButton>
                                    }
                                    title="CLEAR level top up threshold"
                                    type="restriction_level_clear_topup_threshold"
                                    value={restriction_level_clear_topup_threshold}
                                />
                            </td>
                        </tr>
                    )}
                    {!allowDebt && (
                        <tr>
                            <td>FROZEN level threshold</td>
                            <td>
                                Restriction level setting. Defines the number of days customer is allowed to have
                                balance negative before all resources will be stopped.
                            </td>
                            <td>
                                <ConfigPaymentButton
                                    button={
                                        <EditableButton action={undefined}>
                                            {restrictionLevelThresholds.frozen?.days ?? 0} days
                                        </EditableButton>
                                    }
                                    title="Restriction level thresholds"
                                    type="restriction_level_thresholds"
                                    value={restrictionLevelThresholds.frozen?.days ?? 0}
                                    level="FROZEN"
                                    thresholds={restriction_level_thresholds}
                                />
                            </td>
                        </tr>
                    )}
                    {!allowDebt && (
                        <tr>
                            <td>TERMINATED level threshold</td>
                            <td>
                                Restriction level setting. Defines the number of days customer is allowed to have
                                balance negative before all resources will be deleted.
                            </td>
                            <td>
                                <ConfigPaymentButton
                                    button={
                                        <EditableButton action={undefined}>
                                            {restrictionLevelThresholds.terminated?.days ?? 0} days
                                        </EditableButton>
                                    }
                                    title="Restriction level thresholds"
                                    type="restriction_level_thresholds"
                                    value={restrictionLevelThresholds.terminated?.days ?? 0}
                                    level="TERMINATED"
                                    thresholds={restriction_level_thresholds}
                                />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>LIMITED level vCPUs</td>
                        <td>
                            Accounts with LIMITED level can allocate a total of this many vCPUs across all VMs and
                            services.
                        </td>
                        <td>
                            <VmLimitsButton
                                button={<EditableButton action={undefined}>{limits?.vcpu}</EditableButton>}
                                title="LIMITED level vCPUs"
                                value={limits?.vcpu}
                                type="vcpu"
                                limits={limits}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>LIMITED level RAM</td>
                        <td>
                            Accounts with LIMITED level can allocate a total of this much RAM in MB across all VMs and
                            services.
                        </td>
                        <td>
                            <VmLimitsButton
                                button={<EditableButton action={undefined}>{`${limits?.ram_mb} MB`}</EditableButton>}
                                title="LIMITED level RAM in MB"
                                value={limits?.ram_mb}
                                type="ram_mb"
                                limits={limits}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Low balance notice threshold</td>
                        <td>
                            Global threshold for low balance notifications: When a value is specified, notifications are
                            enabled by default. If this is the case and the remaining credit falls below the threshold,
                            the user will be notified. If no value is set, notifications are disabled by default. Note:
                            Users can override this setting and choose to disable notifications or change the threshold.
                            If automated top is enabled, then balance notification sending is disabled.
                        </td>
                        <td>
                            <ConfigPaymentButton
                                button={
                                    <EditableButton action={undefined}>
                                        {low_balance_notice_threshold ? (
                                            <CurrencyPromo value={low_balance_notice_threshold} />
                                        ) : (
                                            "<not set>"
                                        )}
                                    </EditableButton>
                                }
                                title="Low balance notice threshold"
                                type="low_balance_notice_threshold"
                                value={low_balance_notice_threshold ?? ""}
                            />
                        </td>
                    </tr>
                </WTableBody>
            </WAdminTable>
        </>
    );
}

export function GeneralView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <GeneralViewCustom />
        </Suspense>
    );
}
